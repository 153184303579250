body {
  background: var(--body-color);
  font-size: 15px;
  line-height: 19px;
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
}

:root {
  --main-white: #fff;
  --primary-color: #022242;
  --secondary-color: #706e74;
  --lightblue-color: #08578a;
  --orange: #c85803;
  --main-border: #d1d5db85;
  --main-black: #000;
  --main-red: #b90005;
  --bg-color: #3c0000;
  --body-color: #f5f5f8;
  --off-white: #dbd4ce;
  --green: #5cb37d;
  --gray: #adb5bd;
  --yellow: #ffff00;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-family: "Poppins-Regular";
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../fonts/Poppins/poppins-bold.ttf) format("truetype");
  font-family: "Poppins-Bold";
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
  font-family: "Poppins-Medium";
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-display: swap;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* default style start */
*,
*::after,
*::before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  outline: 0;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #4487d2;
}

/* default style end */

/* table style start */

.page-table-main .table-container {
  background-color: transparent;
  box-shadow: none;
  /* max-width: calc(100vw - 340px); */
  overflow: auto;
}

.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: #000;
  font-family: "Poppins-Medium";
  padding: 5px 10px;
  background: #d5d7d8;
  border-bottom: 2px solid var(--main-border);
}

.page-table-main .table .table-td:first-child {
  color: #111827;
}

.page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 5px 10px;
  border-bottom: 1px solid var(--main-border);
}

.table-td .table-data-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* table style end */

/* card style start */
.card-wrape {
  margin-bottom: 15px;
}

.card-wrape .card-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 15px;
  width: 100%;
  display: flex;
}

.card-wrape .card-header .admin-sub-title {
  margin-bottom: 0;
}

.card-wrape .card-body {
  padding: 15px;
  width: 100%;
}

.list-content-action {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.list-content-action:last-child {
  padding-bottom: 0;
  margin-bottom: 5px;
  border-bottom: 0;
}

.list-content-action .list-content h6 {
  font-size: 16px;
  font-family: "Poppins-SemiBold";
  color: #4487d2;
  margin-bottom: 10px;
}

.list-content-action .button-wrape {
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-content-action .button-wrape .btn-main-primary {
  height: auto;
  margin-left: 15px;
}

/* card style end */

/* theme style start */
.box-shadow {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* theme style end */

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: flex-end;
}

/* start input field design css */
.form-group .form-control input,
.form-group .form-control-textarea {
  /* border: 1px solid var(--main-border); */
  border-radius: 5px;
  width: 100%;
  /* padding: 9px 12px; */
  background-color: transparent;
  color: #6b7280;
  font-size: 12px;
  /* line-height: 20px; */
  font-family: "Poppins-Regular";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  height: auto !important;
  padding: 6px 10px;
}

.form-group .form-control input::placeholder,
.cus-datepicker input::placeholder,
.time-range-wrape-label + .timer .input-box.modal-input-box input::placeholder,
.time-range-wrape-label
  + .timer
  + .timer
  .input-box.modal-input-box
  input::placeholder {
  color: #6b7280 !important;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.form-control fieldset {
  border: 1px solid #d1d5db;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d1d5db !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

.form-group .form-control-textarea {
  resize: none;
  height: 150px;
  overflow: auto;
  border: 1px solid #d1d5db !important;
}

.form-group .form-control .Mui-disabled input {
  background: #efefef;
  cursor: not-allowed;
}

/* end input field design css */

.page-border {
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.border-btn-main .border-btn {
  border: 1px solid var(--main-border);
  border-radius: 5px;
  background-color: var(--main-white);
  padding: 5px 10px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular";
}

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Regular";
}

/* end switch css */

/* start pagination css */
.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  margin: 0 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #114a65;
  color: #ffffff;
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: #114a65;
  color: #ffffff;
}

/* end pagination css */

/* start progrss bar css */
.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 5px;
  background-color: var(--lightblue-color);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #111827;
  font-family: "Poppins-Medium";
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Medium";
}

/* end progrss bar css */

/* start error text css */
.input-box .error-text {
  color: red;
  font-size: 12px;
  line-height: 15px;
  font-family: "Poppins-Regular";
}

/* end error text css */

/* / start dropdown css / */
.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-box .form-control .MuiChip-deleteIcon {
  font-size: 16px;
}

.dropdown-box .form-control .MuiAutocomplete-input {
  padding: 9px 10px !important;
  color: #6b7280;
  font-size: 12px !important;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.dropdown-box .form-control fieldset {
  display: none;
}

.dropdown-box .form-control {
  border: 1px solid #d1d5db;
  border-radius: 5px;
  width: 100%;
  /* padding: 0px 5px; */
  background-color: transparent;
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dropdown-box .form-control .MuiChip-filled {
  height: 25px;
  margin: 4px 1px 0px;
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  min-width: 78px;
}

.input-box .dropdown-select .MuiInputBase-root {
  padding: 0;
  height: auto;
  min-height: 35px;
}

.dropdown-box .form-control input {
  padding: 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dropdown-select .MuiSelect-select {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  display: flex;
  /* padding: 8.63px 15px; */
  padding: 8px 12px 7px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

ul li.drop-menuitem,
.drop-menuitem-li {
  font-size: 12px !important;
}

/* / end dropdown css / */

/* start admin dashboard page */
.admin-dashboard-inner-box {
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--main-border);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--main-white);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.admin-dashboard-inner-box .admin-dash-text {
  color: var(--primary-color);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Bold";
}

.admin-dashboard-inner-box .admin-dash-price {
  font-size: 14px;
  line-height: 28px;
  font-family: "Poppins-Medium";
  /* color: var(--secondary-color); */
  color: var(--primary-color);
  font-weight: bold;
}

.admin-dash-digit-bg {
  border: 2px solid var(--main-border);
  height: 40px;
  width: 65px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dash-icons {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.admin-sub-title-main .admin-sub-title {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Medium";
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

.admin-sub-title-main .admin-sub-title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0px;
  height: 20px;
  width: 3px;
  background-color: var(--lightblue-color);
}

.admin-dashboard-table-row {
  padding: 13px 15px;
  align-items: center;
}

.admin-dash-box {
  border-radius: 5px;
  padding: 0px 0px 20px 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.admin-dashboad-row .grid-column {
  margin-bottom: 15px;
}

.display-row > * {
  padding: 0px 8px;
}

.display-row {
  margin-left: -8px;
  margin-right: -8px;
}

.table-lable-main {
  font-family: "Poppins-Regular";
  font-size: 12px;
  line-height: 18px;
}

.paid-lable-dots {
  background-color: var(--green);
}

.paid-lable {
  color: var(--green);
}

.table-lable-dots {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.table-lable-flex {
  display: flex;
  align-items: center;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end admin dashboard page */

/* start admin sidebar css */
.admin-sidebar-logo-main .sidebar-close-btn {
  display: none;
}

.admin-sidebar-icons {
  margin-right: 8px;
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.admin-sidebar-main {
  background: #022242;
  height: 100vh;
  left: -500px;
  position: fixed;
  top: 0;
  width: 290px;
  z-index: 999;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.admin-sidebar-main.active {
  left: 0px;
}

.dashboard-left-main {
  flex: 0 0 290px;
  max-width: 290px;
  width: 100%;
  transition: 0.5s all;
}

.dashboard-left-main.admin-sidebar-deactive {
  flex: 0 0 0px;
  max-width: 0px;
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.admin-sidebar-logo {
  height: 55px;
  width: 100%;
  object-fit: contain;
  margin: auto;
  display: flex;
  order: 2;
}

.close-icon {
  display: none;
}

.admin-sidebar-logo-main {
  padding: 5px 0px;
  background: rgba(255, 255, 255, 1);
  border-right: 1px solid #f0f0f0;
}

.admin-sidebar-list-main {
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 65px);
}

.admin-header-drop-main .drop-header-btn {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-list {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-link {
  font-size: 12px;
  line-height: 19px;
  font-family: "Poppins-Medium";
  color: #ffffff;
  display: flex;
  padding: 8px 10px 8px;
  margin-bottom: 4px;
  width: 100%;
  opacity: 0.75;
  border-radius: 0;
  transition: all 0.15s ease-in-out 0.15s;
  -webkit-transition: all 0.15s ease-in-out 0.15s;
  -moz-transition: all 0.15s ease-in-out 0.15s;
  -ms-transition: all 0.15s ease-in-out 0.15s;
  -o-transition: all 0.15s ease-in-out 0.15s;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.admin-sidebar-list-main .admin-sidebar-link:hover {
  opacity: 1;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.15s ease-in-out 0.15s;
  -webkit-transition: all 0.15s ease-in-out 0.15s;
  -moz-transition: all 0.15s ease-in-out 0.15s;
  -ms-transition: all 0.15s ease-in-out 0.15s;
  -o-transition: all 0.15s ease-in-out 0.15s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
  color: #ffffff;
  opacity: 1;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.15);
  transition: all 0.15s ease-in-out 0.15s;
  -webkit-transition: all 0.15s ease-in-out 0.15s;
  -moz-transition: all 0.15s ease-in-out 0.15s;
  -ms-transition: all 0.15s ease-in-out 0.15s;
  -o-transition: all 0.15s ease-in-out 0.15s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.admin-sidebar-main::-webkit-scrollbar {
  width: 2px;
}

.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.admin-sidebar-submenulist .admin-sidebar-link {
  margin-left: 10px;
}

.admin-sidebar-submenulist
  .admin-sidebar-listitem:first-child
  .admin-sidebar-link {
  margin-top: 20px;
}

.admin-sidebar-main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.admin-sidebar-main::-webkit-scrollbar-thumb {
  background: #888;
}

.admin-sidebar-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end admin sidebar css */

/* start admin header css */
.admin-header-main {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  left: 290px;
  right: 0px;
  z-index: 9;
  padding: 15px 20px 13px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.admin-header-deactive {
  left: 0px;
}

.admin-header-main.active .admin-sidebar-logo {
  opacity: 0;
  display: none;
}

.admin-header-logo-main .admin-bergur-button {
  padding: 0px;
  min-width: 16px;
}

.admin-header-main.admin-header-deactive .admin-sidebar-logo {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  margin-left: 10px;
  height: 35px;
}

.admin-header-logo-main {
  display: flex;
}

.admin-header-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-header-right .admin-header-icon-box {
  padding: 0px;
  min-width: 30px;
  margin-right: 10px;
  display: flex;
}

.admin-header-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.admin-header-profile-icon {
  height: 36px;
  width: 36px;
  object-fit: cover;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
}

/* end admin header css */

/* start admin dashboard page structure css */

.dashboard-right-main {
  flex: 1;
  width: calc(100vw - 290px);
  overflow-x: hidden;
}

.dashboard-containt-main {
  min-height: 100vh;
  padding: 88px 20px 20px 20px;
  background: var(--body-color);
}

.dashboard-main {
  display: flex;
  width: 100%;
}

/* end admin dashboard page structure css */

/* start header profile dropdown  */
.flex-drop-main .admin-header-drop {
  font-size: 12px;
  font-family: "Poppins-Regular";
  line-height: 18px;
  color: #6b7280;
  text-align: start;
  text-transform: capitalize;
}

.flex-drop-main .header-drop-title {
  font-family: "Poppins-Bold";
}

.flex-drop-main {
  display: flex;
  align-items: center;
  gap: 6px;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .MuiPaper-rounded {
  box-shadow: 10px 10px 20px #0000001a;
  border: 1px solid #e5e7eb;
  margin-top: 13px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.drop-header-menu .drop-header-menuitem {
  font-size: 12px;
  line-height: 14px;
  /* color: #6b7280; */
  color: #000;
  font-family: "Poppins-Regular";
  border-bottom: 1px solid #e5e7eb;
  padding: 11px 16px;
}

.drop-header-menu .drop-header-menuitem a {
  display: flex;
  align-items: center;
}

.drop-header-menu ul {
  padding: 0px;
  min-width: 130px;
}

/* end header profile dropdown  */

/* start auth footer social meadia */
.social-main .social-ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  margin: 20px 0px;
  justify-content: center;
}

.social-main .social-ul .social-li {
  padding: 0px;
  margin: 0px;
  width: max-content;
}

.social-box {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #d1d5db;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.social-main .social-ul .social-li:last-child .social-box {
  margin-right: 0px;
}

/* end auth footer social meadia */

/* M style merge here start */
/* <---------tab start-----------> */
.cus-tabs-main-box .cus-tabs-main {
  min-height: 25px;
  margin-bottom: 10px;
}

.cus-tabs-main .cus-tab {
  font-size: 12px;
  line-height: 18px;
  max-width: max-content;
  font-family: "Poppins-Regular";
  color: var(--primary-color);
  padding: 5px 15px;
  min-height: 25px;
  text-transform: capitalize;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.cus-tabs-main .cus-tab.Mui-selected {
  color: var(--main-white);
  background-color: var(--lightblue-color);
}

.cus-tabs-main .MuiTabs-indicator {
  background-color: var(--lightblue-color);
  height: 2px;
  display: none;
}

.cus-tabpanel .edit-highlight-text {
  color: #111827;
  font-family: "Poppins-Medium";
  margin-bottom: 4px;
}

.cus-tabpanel .edit-para-text {
  margin-bottom: 24px;
  color: #6b7280;
}

.cus-tabpanel > .MuiBox-root {
  padding: 0 0;
}

.card-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: var(--main-white);
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.card-main:last-child {
  margin-bottom: 0;
}

.job-title-label {
  font-size: 12px;
  color: var(--secondary-color);
  line-height: 16px;
  font-family: "Poppins-Medium";
  word-break: break-all;
}

.job-title-text-label {
  font-size: 14px;
  color: #a8a8a8;
  word-break: break-word;
  font-family: "Poppins-Regular";
}

.danger-text {
  color: #dd4b40 !important;
}

.card-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Medium";
  padding: 6px 12px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-main-blue .btn-blue,
.btn-main-blue .btn-blue:hover {
  background-color: var(--lightblue-color);
  border-radius: 5px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  padding: 5px 10px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-main-orange .btn-orange,
.btn-main-orange .btn-orange:hover {
  background-color: var(--orange);
  border-radius: 5px;
  min-width: 30px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  padding: 5px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-main-red .btn-red,
.btn-main-red .btn-red:hover {
  background-color: var(--main-red);
  border-radius: 5px;
  min-width: 30px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  padding: 5px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-main-green .btn-green,
.btn-main-green .btn-green:hover {
  background-color: rgb(92, 160, 92);
  border-radius: 5px;
  min-width: 30px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  padding: 5px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-main-yellow .btn-yellow,
.btn-main-yellow .btn-yellow:hover {
  background-color: var(--yellow);
  border-radius: 5px;
  min-width: 30px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  padding: 5px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-main-secondary .btn-secondary,
.btn-main-secondary .btn-secondary:hover {
  background-color: var(--secondary-color);
  border-radius: 5px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  padding: 5px;
  min-width: 30px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btn-main-primary .btn-primary-add-rate,
.btn-main-primary .btn-primary-add-rate:hover {
  background-color: #4487d2;
  color: #ffffff;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  padding: 8px 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.active-job-main .card-right,
.complete-job-main .card-right {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.prof-img {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.officer-manag-main .card-left {
  display: flex;
  align-items: center;
}

.officer-tab-main .card-right {
  display: flex;
  align-items: center;
}

.right-btn-group {
  display: flex;
}

.bg-approve {
  background: #26c536 !important;
}

.left-arrow-ic {
  width: 20px;
}

.job-title-header {
  padding: 10px;
  text-align: center;
  width: 100%;
  border: 2px solid var(--main-border);
  border-radius: 5px;
  margin-left: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.assigned-officer-main {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  background: #ffffff;
}

.assigned-officer-Detail .card-left {
  display: flex;
  align-items: center;
}

.assigned-officer-Detail .card-right {
  align-items: center;
}

.main-title {
  font-size: 17px;
  line-height: 26px;
  color: var(--primary-color);
  font-family: "Poppins-Medium";
}

.assigned-officer-header {
  margin-bottom: 10px;
  border-bottom: 1px solid #f0eff2;
  padding-bottom: 10px;
}

.assigned-officer-main .job-title-text {
  margin-bottom: 0;
}

.assigned-officer-main .job-title-text.m-b-0 {
  margin-bottom: 0;
}

.text-right {
  text-align: right !important;
  width: 100%;
}

/* <---------Details of completed jobs end-----------> */
/* <---------Details of pending profile start-----------> */
.detail-pending-profile-main .assigned-officer-header {
  display: flex;
  align-items: center;
  position: relative;
}

.detail-pending-profile-main .left-arrow-ic {
  margin-right: 10px;
}

.document-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #f0eff2;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
  margin-bottom: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.document-wrapper .document-btn-grp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-user-modal-inner-main {
  position: relative;
}

.add-user-modal-inner-main .job-title-text {
  margin-bottom: 20px;
  align-items: center;
  position: relative;
}

.pending-after-main {
  display: flex;
}

.modal .pending-after-officer-modal {
  max-width: 1000px !important;
}

.pending-after-officer-modal .pending-job-detail {
  width: 80%;
}

.pending-after-officer-modal .pending-ofiicer-detail {
  width: 100%;
}

.pending-after-officer-modal .document-wrapper .btn-main-primary .btn-primary {
  max-width: 200px;
  font-size: 12px;
  padding: 10px 20px;
}

.pending-after-officer-modal .document-btn-grp {
  margin-top: 15px;
}

.pending-after-main .modal-title {
  text-align: center;
}

.modal .document-btn-grp {
  margin-top: 0px;
  justify-content: center;
}

.dashboard-content {
  background: var(--main-white);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/* <---------Pending job Request after officer accepted modal end-----------> */
.mr-10 {
  margin-right: 10px;
}

.jus-end {
  justify-content: flex-end;
}

.modal .rating-main-modal {
  max-width: 600px !important;
}

.assigned-officer-Detail .assign-active-job {
  width: 100%;
}

.assigned-officer-Detail .assign-active-job.half-section {
  width: 50%;
}

.cus-datepicker.full > div {
  width: 100%;
}

.cus-datepicker .MuiInputBase-input {
  border: 1px solid var(--main-border);
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.cus-datepicker .MuiInputBase-input + fieldset {
  display: none;
}

.cus-datepicker .MuiOutlinedInput-notchedOutline {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.mx-5 {
  margin: 0 5px !important;
}

.cus-item-top {
  align-items: flex-start;
}

.bg-none {
  background: none;
}

.officer-detail.admin-dashboad-row .grid-column {
  margin-bottom: 0px;
}

.modal-close-icon {
  cursor: pointer;
}

/* M style merge here end */

/* N style merge here start */

/* start user list page */

.userdata-btn-flex {
  display: flex;
}

.userdata-btn-flex button {
  min-width: 30px;
}

.userlist-data {
  display: flex;
  align-items: center;
  width: 100%;
}

.userlist-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.userlist-inner-btn-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.down-icon,
.plus-icon,
.left-arrow-ic {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 9px;
}

.export-btn-main .export-btn,
.export-btn-main .export-btn:hover {
  margin-right: 10px;
}

.user-search-box {
  position: relative;
  margin-right: 10px;
}

.user-search-box .form-control input::placeholder,
.dropdown-box .form-control.multiple-dd .MuiAutocomplete-input::placeholder {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.search-grey-img {
  position: absolute;
  left: 13px;
  top: 9px;
  height: 14px;
  width: 14px;
  margin-right: 8px;
}

.user-search-box .form-control input {
  padding: 5.38px 35px;
  background: var(--main-white);
}

.user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.userlist-btn-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.user-list-flex .user-list-page-title {
  margin-bottom: 0px;
  font-size: 17px;
  line-height: 23px;
  color: var(--primary-color);
  font-family: "Poppins-SemiBold";
}

/* end user list page */

/* start add user modal css */

.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: var(--primary-color);
  font-size: 14px;
  font-family: "Poppins-Bold";
  line-height: 19px;
}

.modal-user-btn-flex {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
}

.modal .modal-inner {
  padding: 0;
  max-width: 550px;
  width: 100%;
  border: 1px solid var(--main-border);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-header {
  justify-content: space-between;
  padding: 10px 15px;
  display: flex;
  margin-bottom: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 6px 20px;
}

.modal-body {
  padding: 15px;
  max-height: calc(100vh - 117px);
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-footer {
  padding: 15px;
  border-top: 1px solid var(--main-border);
}

.modal-footer-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* end add user modal css */

/* start delete user modal css */

.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 500px;
  width: 100%;
  border: 1px solid var(--main-border);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.delete-modal-inner-main .delete-modal-title {
  color: var(--primary-color);
  font-size: 28px;
  line-height: 42px;
  font-family: "Poppins-Medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #6b7280;
  margin-bottom: 26px;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 5px;
  border: 1px solid var(--main-border);
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.delete-modal-btn-flex .modal-cancel-btn {
  color: #6b7280;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background-color: #e4004d;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

/* start filter css */

.filter-main {
  position: relative;
}

.filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 360px;
  max-width: 360px;
}

.filter-header {
  background: var(--primary-color);
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}

.filter-header svg {
  width: 15px;
  height: 15px;
}

.filter-close-icon {
  height: 21px;
  width: 21px;
  object-fit: contain;
}

.filter-header .filter-title {
  font-size: 16px;
  font-family: "Poppins-SemiBold";
  line-height: 22px;
  color: var(--main-white);
}

.filter-inner-main {
  padding: 15px;
  background-color: var(--main-white);
}

.filter-btn-main {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.filter-footer {
  border-top: 1px solid var(--main-border);
  padding: 15px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.filter-checkbox-main .checkbox-lable {
  margin-right: 10px;
}

/* end filter css */

.input-box .form-lable {
  font-size: 12px;
  line-height: 19px;
  font-family: "Poppins-Medium";
  margin-bottom: 3px;
  color: var(--secondary-color);
  text-transform: capitalize;
  min-height: 19px;
}

.input-box {
  margin-bottom: 10px;
}

.checkbox-lable .MuiFormControlLabel-label {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
}

.user-manag-main .card-left {
  display: flex;
  align-items: center;
}

.user-manag-main .card-right {
  display: flex;
  align-items: center;
}

.user-modal-btn {
  margin-right: 15px !important;
}

.user-modal-btn-color {
  color: white !important;
}

.modal-btn-left {
  margin-left: auto !important;
}

.admin-assigned-officer-Detail {
  display: flex;
}

.admin-card-main {
  width: 50%;
}

.admin-card-main-box {
  margin-right: 15px;
}

.admin-card-left {
  padding: 15px 15px;
}

.admin-card-right {
  padding: 15px;
}

.page-table-main .cus-tabs-main > div {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
}

.page-table-main td .btn-primary img {
  margin-right: 0;
}

.space-hr-line {
  width: 100%;
  height: 1px;
  background: #dadada;
  margin: 12px 0 10px;
}

.time-range-wrape .input-box.modal-input-box {
  margin-bottom: 0 !important;
}

.time-range-wrape-label {
  margin-bottom: 0 !important;
}

.time-range-wrape-label p {
  margin-bottom: 0 !important;
}

.timer .sub-label {
  font-size: 13px;
  font-family: "Poppins-Medium";
  color: #666666;
}

.time-range-wrape-label + .timer,
.time-range-wrape-label + .timer + .timer {
  padding-top: 0 !important;
}

.time-range-wrape-label + .timer fieldset,
.time-range-wrape-label + .timer + .timer fieldset {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.time-range-wrape-label + .timer .input-box.modal-input-box > div,
.time-range-wrape-label + .timer + .timer .input-box.modal-input-box > div {
  overflow: inherit;
}

.time-range-wrape-label + .timer .input-box.modal-input-box > div > div > div,
.time-range-wrape-label
  + .timer
  + .timer
  .input-box.modal-input-box
  > div
  > div
  > div {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.time-range-wrape-label + .timer .input-box.modal-input-box input,
.time-range-wrape-label + .timer + .timer .input-box.modal-input-box input {
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.form-group.date-picker-wrape .first-div {
  width: 100%;
}

.form-group.date-picker-wrape .first-div > div {
  border-radius: 5px;
}

.form-group.date-picker-wrape > div {
  padding-top: 0;
}

.form-group.date-picker-wrape .first-div > div input {
  width: 100%;
  padding: 13px 15px;
  background-color: transparent;
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.rating-sec {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: var(--main-white);
  border-radius: 5px;
  border: 1px solid var(--main-border);
  margin-bottom: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

h6.rating-head-label {
  font-size: 18px;
  font-family: "Poppins-SemiBold";
  color: #114a65;
  margin-bottom: 10px;
}

.rating-title-text {
  display: flex;
}

.rating-title-label {
  font-size: 16px;
  line-height: 23px;
  color: #2e2e2e;
  font-family: "Poppins-Medium";
}

p.rating-title-text-label {
  font-size: 15px;
  line-height: 23px;
  color: #747474;
  font-family: "Poppins-Regular";
  margin-bottom: 5px;
}

.rating-title-text-label span {
  font-family: "Poppins-Medium";
  font-size: 15px;
  color: #444444;
}

.rating-title-right {
  display: flex;
  align-items: flex-end;
}

/* fitler section style start */
.card-wrape .card-wrape {
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(18, 38, 63, 0.3);
}

.filter-card.card-wrape {
  width: 100%;
  margin-bottom: 5px;
}

.filter-card.card-wrape .user-list-flex {
  margin-bottom: 15px;
}

/* modal general style start */
.modal .add-modal-inner-main {
  /* padding: 24px 24px 30px 24px; */
  /* padding: 0px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; */
}

/* modal general style end */

.dashboard-content.user-list-content {
  margin-bottom: 20px;
}

.user-identifier-wrape {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.user-identifier-wrape-left {
  max-width: 180px;
  display: flex;
  justify-content: center;
}

.user-identifier-wrape-left .back-btn {
  max-width: 20px;
  margin-right: 20px;
}

.user-identifier-wrape-left img {
  /* width: 100%;
  height: auto; */
  object-fit: cover;
  width: 60px;
  height: 60px;
  max-width: 100%;
  border-radius: 50%;
}

.user-identifier-wrape-right {
  padding-left: 30px;
}

.user-identifier-wrape-right .edit-btn {
  background: #4487d2;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 6px;
}

.bg-none {
  background: none;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

/* a style merge here end */
.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.to-text {
  display: flex;
  align-items: center;
}

.money-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 9px;
}

.input-box-wrapper {
  padding: 15px;
}

/* .cus-tabs-main {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
} */
.cus-tabpanel {
  margin-top: 15px;
}

/* .css-1gsv261 {
  border-bottom: 1px solid white !important;
} */
.help-support-page-wrape .cus-tabpanel,
.home-page-content .cus-tabpanel {
  margin-top: 0;
}

fieldset {
  border: 1px solid #d1d5db !important;
}

.modal-footer .border-btn {
  background: #26c536;
  color: #ffffff;
}

.modal-footer .border-btn:hover {
  background: #26c536;
  color: #ffffff;
}

.pending-after-main .modal-title.text-left-align {
  text-align: left;
  margin-bottom: 15px;
}

/* new auth page css  start*/
/* / start admin login css / */

.admin-login-main-flex {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.admin-login-left-main {
  width: 100%;
  max-width: 60%;
  flex: 0 0 60%;
}

.admin-login-right-main {
  width: 100%;
  max-width: 40%;
  flex: 0 0 40%;
}

.login-left-bg {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-image: url(../images/jpg/login-bg.jpg);
  background-size: cover;
  background-position: right;
  width: 100%;
}

.admin-login-main {
  max-width: 380px;
  margin: auto;
  width: 100%;
}

.login-btn-main .login-btn {
  margin-top: 28px;
}

.checkbox-main-new .checkbox-lable {
  /* color: #BCBBBE; */
  color: #022242;
  margin-left: 0px;
  font-size: 15px;
  margin-right: 0px;
  font-family: "Poppins-Regular";
}

.checkbox-main-new {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkbox-main-new .MuiButtonBase-root {
  margin-right: 0px;
  padding: 0px;
}

.checkbox-lable .MuiFormControlLabel-label {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
}

.admin-login-inner .admin-wel-text {
  color: var(--secondary-color);
  font-size: 24px;
  line-height: 25px;
  font-family: "Poppins-Bold";
  margin-bottom: 10px;
}

.admin-login-inner .admin-sign-para {
  margin-bottom: 32px;
  color: var(--secondary-color);
}

.admin-forgot-para {
  color: var(--secondary-color);
}

.input-box .MuiInputBase-root {
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  height: 37px;
  padding-right: 0;
}

.input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.input-box .form-control-eye input {
  border-radius: 5px;
  width: 100%;
  padding: 9px 8px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-right: 0;
}

/* / end admin login css / */
/* / start or text design / */

.or-text {
  text-align: center;
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  margin: 30px 0px 0px;
  position: relative;
  max-width: max-content;
  background-color: #fff;
}

.condition-content {
  text-align: center;
}

.condition-content .condition-text {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
}

.condition-content .condition-span {
  color: #022242;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  text-decoration: none;
}

.or-text-main {
  position: relative;
  margin: 30px 0px;
}

.or-text-main .or-cocntent-text {
  background: var(--body-color);
  margin: auto;
  z-index: 9;
  position: relative;
  text-align: center;
  width: 44px;
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
}

.or-text-main::after {
  content: "";
  width: 100%;
  height: 1px;
  background: var(--secondary-color);
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* / end or text design / */

/* / start otp page / */

.otp-input-box .form-group .form-control input {
  max-width: 70px;
  text-align: center;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.otp-input-box .form-group .MuiFormControl-root {
  max-width: 70px;
}

.otp-input-box .form-group .form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.otp-flex-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-input-box {
  margin-right: 15px;
  margin-bottom: 10px;
}

.otp-flex-main .otp-input-box:last-child {
  margin-right: 0px;
}

/* / end otp page / */

/* / start chnage password page css / */

.change-pass-main {
  border: 1px solid #d1d5db;
  border-radius: 5px;
  padding: 20px;
  max-width: 514px;
  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.checkbox-main-new .MuiButtonBase-root span {
  border-color: #d1d5db;
  border-radius: 4px;
}

.position-relative {
  position: relative;
}

.login-overlay {
  display: flex;
  width: 100%;
  height: 100%;
  background: #02224254;
  position: absolute;
  top: 0;
  z-index: 1;
  right: 0;
  left: 0;
}

.form-control-eye input::placeholder {
  opacity: 1;
}

.otp-main {
  /* display: flex;
  margin: auto auto; */
}

.otp-main .form-group {
  /* margin: 8px; */
}

.otp-main .form-group .form-control input {
  border-radius: 5px;
  /* padding: 10px 23px; */
  font-size: 18px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.otp-main .form-control fieldset {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.register-inner {
  height: 218px;
  overflow: auto;
  padding-right: 10px;
  margin-right: -20px;
}

/* width */
.register-inner::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.register-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.register-inner::-webkit-scrollbar-thumb {
  background: #d1d5dbfc;
}

/* Handle on hover */
.register-inner::-webkit-scrollbar-thumb:hover {
  background: #d1d5dbba;
}

/* new auth page css end */
.modal-password-border .form-group .form-control input {
  border: 0px;
}

/* new auth page css end */
.not-found-data {
  text-align: center;
  padding: 10px 0;
}

.buttom-btn-wrape {
  display: flex;
  justify-content: flex-end;
}

.buttom-btn-wrape .btn-main-primary {
  margin-left: 10px;
}

.delete-icon {
  height: 40px;
  width: 40px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.delete-modal-body .delete-title {
  color: var(--primary-color);
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  font-family: "Poppins-Medium";
  margin-bottom: 10px;
  margin-top: 20px;
}

.delete-modal-body .delete-message {
  color: var(--secondary-color);
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  font-family: "Poppins-Regular";
  max-width: 355px;
  margin: auto;
}

.cus-tabpanel.m-t-15 {
  margin-top: 15px;
}

.btn-main-primary .bg-suspend {
  background-color: var(--main-red) !important;
}

.btn-main-primary .bg-active {
  background-color: var(--green) !important;
}

.small-content {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.small-content .job-title-text {
  width: 100%;
}

.double-picker {
  display: flex;
  align-items: center;
}

.double-picker span {
  font-size: 13px;
  margin: 0 5px;
  color: #bcbbbe;
}

.assigned-officer-header .edit-icon-btn {
  margin: 0 5px 0 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  transition: all 0.15s ease-in-out 0.15s;
  -webkit-transition: all 0.15s ease-in-out 0.15s;
  -moz-transition: all 0.15s ease-in-out 0.15s;
  -ms-transition: all 0.15s ease-in-out 0.15s;
  -o-transition: all 0.15s ease-in-out 0.15s;
  background-color: #4487d2;
  text-transform: capitalize;
  position: absolute;
  right: 0;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assigned-officer-header .edit-icon-btn:hover {
  background-color: #4487d2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.assigned-officer-header .edit-icon-btn img {
  margin-right: 0;
}

.small-content .job-title-text {
  width: 100%;
}

.double-picker {
  display: flex;
  align-items: center;
}

.double-picker span {
  font-size: 13px;
  margin: 0 5px;
  color: #bcbbbe;
}

.agent-profile-wrapper .profile-from-section {
  margin-top: 0px;
}

.no-data-wrape {
  width: 100%;
  height: 65%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.no-data-wrape h2.admin-page-title {
  text-transform: uppercase;
  font-family: "Poppins-SemiBold";
  width: 100%;
  text-align: center;
  color: #2e2e2e;
  font-size: 40px;
  margin-bottom: 30px;
}

.no-data-wrape .no-data-img {
  height: 290px;
  margin-bottom: 20px;
  max-width: 90%;
  margin-top: 30px;
}

.double-dd-wrape {
  display: flex;
  gap: 10px;
}

.double-dd-wrape .dd-section {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

/* .double-dd-wrape .dd-section .dropdown-select {
  width: calc(100% - 62px);
} */

.double-dd-wrape .dd-section span.form-lable {
  width: 70px;
  margin-bottom: 0;
  padding: 0 5px;
}

.card-left.backarrow-include {
  align-items: center;
}

.cus-tabs-main-box .Mui-disabled.MuiTabs-scrollButtons {
  opacity: 0.35;
}

.cus-tabs-main-box .MuiTabs-scrollButtons {
  width: 30px;
}

[placeholder] {
  text-overflow: ellipsis;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-overflow: ellipsis;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-overflow: ellipsis;
}

:-ms-input-placeholder {
  /* IE 10+ */
  text-overflow: ellipsis;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-overflow: ellipsis;
}

.page-table-main.content-table-main table {
  min-width: 100%;
}

.page-table-main.content-table-main table th:last-child,
.page-table-main.content-table-main table td:last-child {
  width: 130px;
}

.mobile-more-btn a.more-btn {
  display: none;
}

#btn-toggle-popup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.category-page-table .action-head,
.category-page-table .action-cell {
  width: 100px;
}

.role-check-box svg {
  color: #1a3768;
  height: 20px;
  width: 20px;
}

.suspend_label {
  color: #b90005;
  font-family: "Poppins-Bold";
  font-size: 12px;
}

.suspend_label.text-danger {
  color: #b90005 !important;
  font-family: "Poppins-Bold" !important;
  font-size: 12px !important;
}

/* .status_tag.MuiChip-root{
  background-color: #07bc0c;
} */
.status_tag {
  color: #07bc0c;
  font-family: "Poppins-Bold";
  font-size: 12px;
}

.status_red {
  color: #bc0d07;
  font-family: "Poppins-Bold";
  font-size: 12px;
}

.status_blue {
  color: #374bcc;
  text-align: end;
  font-family: "Poppins-Bold";
  font-size: 12px;
}

.pending-officer-margin {
  margin-left: 120px;
}

.job_title_status {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.officer_accept_job_card {
  padding: 20px;
  background: var(--main-white);
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
  margin-bottom: 15px;
}

.panding_officer_job_detail_card {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--main-border);
}

/* .panding_officer_job_detail_wrap {} */

.panding_officer_job_detail_wrap .pending_officer_heading {
  color: var(--primary-color);
  margin-bottom: 5px;
  font-family: "Poppins-Medium";
  font-size: 16px;
  line-height: 22px;
}

.user_job_history_card_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.user_job_history_card_btn .btn-main-primary {
  width: 100px;
}

.user_job_history_chat {
  margin-right: 20px;
}

.assigned_officer_card,
.associate_agency_card {
  width: 50%;
  margin: 0 5px;
}

.assigned_officer_card {
  width: 70%;
}

.card-right .btn-box-chat {
  display: flex;
}

/* chat-box-modal */

.filter-main .chat-input-btn-box {
  display: flex;
  align-items: end;
  padding: 10px 15px;
  position: relative;
  border-top: 1px solid var(--main-border);
}

/* .chat-input-btn-box .chat-text-fied{
  width: 340px !important;

} */
.chat-input-btn-box .chat-text-fied textarea {
  font-size: 14px !important;
  font-family: "Poppins-Regular" !important;
}

.chat-input-btn-box .chat-text-fied input {
  height: 100px;
}

.chat-text-fied .MuiInputBase-root textarea::-webkit-scrollbar {
  opacity: 0;
}

.chat-text-fied .MuiInputBase-root textarea::-webkit-scrollbar-track {
  opacity: 0;
}

.chat-input-btn-box .chat-btn {
  color: #4487d2;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(-50%, -50%);
  justify-content: end;
  padding: 0;
}

.filter-close-icon-officer {
  background-color: #4487d2;
}

.btn-primary-view-officer-details {
  margin-top: 15px;
}

.chat-input-btn-box .chat-btn svg {
  width: 18px;
}

.chat-box-section .MuiPaper-root {
  justify-content: space-between;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.chat-messages {
  height: 80vh;
  overflow-y: scroll;
}

.chat-messages .sent {
  text-align: right;
  padding: 8px;
  background: #d8eaff;
  border-radius: 5px;
}

.chat-messages .sent p {
  font-size: 14px;
}

.chat-messages .received {
  text-align: left;
}

.chat-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.chat-input input {
  flex: 1;
  margin-right: 0.5rem;
}

.message-timestamp {
  font-size: 8px;
}

.chat-messages {
  margin: 16px 16px 0px 16px;
  /* height: calc(100vh - 100px); */
  /* overflow: auto; */
  display: flex;
  justify-content: end;
  flex-direction: column;
  word-break: break-all;
}

.chat-messages::-webkit-scrollbar {
  width: 0px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #ede2e2;
}

.filter-title .chat-menu-icon {
  margin: 3px 7px 3px 0px;
  cursor: pointer;
}

.filter-header .chat-title {
  display: flex;
}

.chat-box-section .chat-header {
  padding: 12px 16px;
}

.chat-userlist-main-box {
  height: 100vh;
  /* fatma changes */
  padding: 10px;
}

.chat-userlist-main-box .user-text-box {
  /* background-color: #b5b8b8; */
  font-size: large;
  padding: 7px 0px 7px 0px;
  box-shadow: 1px 1px 4px 1px rgb(172, 172, 172);
  margin: 7px 2px;
  border-radius: 10px;
  cursor: pointer;
}

.user-text-box .user-text-label {
  margin-left: 10px;
  color: #2d97dd;
  font-family: "Poppins-Medium";
  font-size: 15px;
  line-height: 22px;
  font-weight: 500px;
  font-family: "Poppins-Regular";
}

.document-btn-grp .disble-bg-approve {
  background-color: rgb(215, 210, 210);
}

.document-btn-grp .disble-bg-approve:hover {
  background-color: rgb(215, 210, 210);
}

.document-btn-grp .disble-bg-rejected {
  background-color: rgb(215, 210, 210);
}

.document-btn-grp .disble-bg-rejected:hover {
  background-color: rgb(215, 210, 210);
}

.chat-title .chat-user-name {
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  font-family: "Poppins-Regular";
  font-family: "Poppins-SemiBold";
}

.chat-close-icon {
  cursor: pointer;
}

.chat-user-name .typing-status {
  color: white;
  font-size: 10px;
}

.ck-editor img {
  height: 400px;
  width: 400px;
}

.job-text-width-content .job-title-text .job-title-label {
  width: 33%;
}

.job-text-width-content .job-title-text .job-title-text-label {
  flex: 1;
}

.job-width-pending {
  width: 350px;
}

.job-width-pending-flex {
  flex: 1;
}

.job-start-align-pending {
  align-items: start !important;
}

.view-design {
  margin-right: 10px;
}

.view-design .btn-primary {
  background-color: #4487d2;
  border-radius: 5px;
  width: 100%;
  max-width: 380px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  font-family: "Poppins-SemiBold";
  padding: 8px 30px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.job-title-text .job-description-width {
  width: 115px;
}

/* .job-title-text .job-title-text-label {
  flex: 1;
} */

.align-start-job {
  align-items: start;
}

.suspend-error-box {
  position: absolute;
  bottom: -24px;
  margin-left: 6px !important;
}

.admin-list-pd-btn button {
  min-width: 36px;
  font-size: 8px;
  padding: 4px !important;
}

.work-policy {
  line-height: 10px;
  height: 40px;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 5px;
  border: 3px solid #f6f6f6;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.card-right.job_title_status {
  align-items: end;
}

.profile-box-w {
  max-width: 380px;
  margin: 0 auto;
}

.card-right-switch {
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkbox-main-new .MuiTypography-root {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
}

.checkbox-main-new .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 5px;
}

.checkbox-main-new svg {
  height: 20px;
  width: 20px;
}

.Mui-error {
  margin-left: 0px !important;
}

.admin-icons {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.admin-modal-hgt-scroll {
  max-height: calc(100vh - 107px);
  overflow: auto;
  padding: 0 5px 0px 0px;
  margin-right: -5px;
}

.officer-profile-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.officer-detail .officer-detail-title {
  color: var(--lightblue-color);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Medium";
}

.officer-detail .officer-detail-email {
  color: var(--secondary-color);
  font-size: 11px;
  line-height: 15px;
  font-family: "Poppins-Medium";
  margin-bottom: 5px;
}

.officer-detail .officer-detail-numebr {
  color: var(--secondary-color);
  font-size: 11px;
  line-height: 15px;
  font-family: "Poppins-Regular";
}

.admin-body-overflow .MuiTooltip-tooltip {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--selected-color);
  border-radius: 5px;
  color: var(--main-white);
  padding: 4px 8px;
  font-size: 13px;
  line-height: 25px;
  font-family: "Poppins-Medium";
}

.admin-body-overflow .MuiTooltip-tooltip .MuiTooltip-arrow::before {
  color: var(--selected-color);
}

.data-not-found-main {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 9px;
  width: 100%;
  margin: 10px 0px 0px;
}

.data-not-found-main .data-not-found-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-family: "Poppins-Medium";
  text-align: center;
}

.login-btn-main .login-btn {
  width: 100%;
}

.admin-list-pd-btn-main {
  margin: 5px 0px 0px;
}

.admin-list-pd-btn-main .admin-list-pd-btn,
.admin-list-pd-btn-main .admin-list-pd-btn:hover {
  color: #ffffff;
  font-size: 9px;
  line-height: 13px;
  font-family: "Poppins-Regular";
  padding: 2px;
  text-transform: capitalize;
  background-color: var(--lightblue-color);
  border-radius: 10px;
  min-width: 40px;
}

.cus-datepicker .MuiFormControl-root {
  width: 100%;
}

.modal-title-flex {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.common-card {
  background-color: var(--main-white);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 10px;
  padding: 15px;
}

.available-title-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0px;
}

.time-form-group .MuiStack-root {
  padding: 0;
  overflow: hidden;
}

.form-group.time-form-group .form-control input {
  border: 0;
}

.availbale-card-box {
  margin: 0px 0px 30px;
}

.work-title-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
}

.work-title-flex:last-child {
  margin-bottom: 0;
}

.work-value-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  word-break: break-word;
  font-family: "Poppins-Regular";
}

.work-title-lable {
  color: var(--lightblue-color);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Medium";
  min-width: 185px;
}

.user-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0px;
}

.client-history-inner-cards {
  margin-bottom: 15px;
}

.job-managment-card {
  margin-bottom: 15px;
}

.view-office-btn-main {
  display: flex;
  gap: 10px;
  align-items: center;
}

.details-active-flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.dropdown-box .drop-form-control {
  width: 100%;
  min-height: 35px;
}

/* .review-rating-table-main {
  min-width: 1200px;
} */

.page-table-btn-main {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.available-title-flex .admin-sub-title {
  margin-bottom: 0;
}

.no-text-decoration {
  background-color: transparent !important;
  text-decoration: none !important;
}

.admin-input-bottom-border-field input {
  padding: 10px;
}

.admin-input-bottom-border-field .MuiInputBase-root,
.admin-input-bottom-border-field .MuiInputBase-root:hover,
.admin-input-bottom-border-field .MuiInputBase-root.Mui-focused {
  background-color: transparent;
}

.admin-sub-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.admin-sub-flex .admin-sub-title-main .admin-sub-title {
  margin-bottom: 0;
}

.job-mamangment-card {
  margin-bottom: 15px;
}

.job-mamangment-inner-card {
  border: 1px solid var(--main-border);
}

.accordian-main .accordian-summary {
  background-color: #d5d7d8;
  min-height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 5px 16px;
}

.accordian-main .accordian {
  margin-bottom: 10px;
  box-shadow: none;
}

.accordian-main .accordian .MuiAccordionDetails-root {
  padding: 15px;
}

.accordian-main .accordian-details {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-family: "Poppins-Regular";
  border: 1px solid var(--main-border);
  word-wrap: break-word;
}

.accordian-main .MuiAccordion-root::before {
  background-color: transparent;
}

.accordian-main .MuiAccordionSummary-content.Mui-expanded {
  margin: 5px 0px;
}

.accordian-main .MuiAccordionSummary-content {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-family: "Poppins-Medium";
  margin: 5px 0px;
}

.accordian-main .accordian:last-child {
  margin-bottom: 0px;
}

.accordian-main .accordian.Mui-expanded {
  margin: 10px 0;
}

.accordian-main .accordian-summary.Mui-expanded {
  min-height: 30px;
  padding: 5px 16px;
}

.accordian-main .admin-sub-title {
  margin-bottom: 0;
}

.page-inner-title-flex .page-inner-title {
  color: var(--orange);
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Medium";
}

.page-inner-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-sub-title-main .admin-sub-title-value-text {
  color: var(--lightblue-color);
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Medium";
}

.admin-sub-title-flex {
  display: flex;
}

.chat-userlist-box .chat-userlist-text {
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Medium";
  color: var(--main-white);
  text-transform: capitalize;
}

.chat-userlist-box {
  margin-bottom: 10px;
  background-color: var(--lightblue-color);
  border-radius: 5px;
  padding: 5px 10px;
  max-width: max-content;
}

.chat-userlist-main-box {
  height: calc(100vh - 144px);
  overflow-y: scroll;
}

.chat-header img {
  height: 15px;
  width: 15px;
}

.chat-right-text {
  background-color: var(--primary-color);
  margin-bottom: 10px;
  border-radius: 10px 10px 1px 10px;
  padding: 8px 10px;
  max-width: max-content;
  margin-left: auto;
}

.chat-left-text {
  background-color: #d7d7d7;
  margin-bottom: 10px;
  border-radius: 10px 10px 10px 0px;
  padding: 8px 10px;
  max-width: max-content;
}

.chat-left-text .chat-userlist-text {
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Medium";
  color: var(--primary-color);
  text-transform: capitalize;
  font-family: "Poppins-Regular";
}

.chat-right-text .chat-userlist-text {
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Medium";
  color: var(--main-white);
  text-transform: capitalize;
  font-family: "Poppins-Regular";
}

.work-title-lable.work-title-total {
  min-width: max-content;
}

.agent-details-switch-main {
  margin-bottom: 10px;
}

.profile-edit-flex {
  position: relative;
  max-width: 80px;
  margin-bottom: 10px;
  margin: 0 auto;
}

.upload-prof-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
}

.profile-file-upload-main {
  background: var(--orange);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.profile-file-upload-main .profile-upload-btn {
  padding: 0;
}

.profile-edit-icon {
  height: 12px;
  width: 12px;
  object-fit: contain;
}

.change-pass-text-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.change-pass-text-main .change-pass-link {
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 0;
  color: #dd4b40;
  text-decoration: none;
  cursor: pointer;
}

.profile-main {
  max-width: 400px;
}

.notification-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.img-notification {
  height: 50px;
  width: 50px;
  object-fit: contain;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.notification-text-box .notification-text {
  color: var(--lightblue-color);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Medium";
  margin-bottom: 3px;
}

.notification-text-box .notification-sub-text {
  color: var(--secondary-color);
  font-size: 11px;
  line-height: 15px;
  font-family: "Poppins-Medium";
  margin-bottom: 3px;
}

.notification-text-box .notification-time {
  color: var(--secondary-color);
  font-size: 11px;
  line-height: 15px;
  font-family: "Poppins-Regular";
}

.notification-main-box {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.admin-client-history-sec {
  margin-bottom: 15px;
}

.payment-config-table-main .table {
  min-width: 1400px;
}

.datepicker-wrape div {
  width: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}

.datepicker-wrape .MuiStack-root {
  padding: 0px;
}

.table-btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-data-flex {
  display: flex;
  gap: 10px;
}

.job-btn-flex {
  display: flex;
  gap: 10px;
}

.btn-main-primary .btn-primary-supervisor,
.btn-main-primary .btn-primary-supervisor:hover {
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 5px;
  font-family: "Poppins-Regular";
  padding: 8px 15px;
  margin-left: 600px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.edit-btn-flex {
  display: flex;
  gap: 10px;
}

.active-job-sec {
  margin: 10px 0px;
}

.officer-detail-flex {
  display: flex;
  gap: 10px;
  align-items: center;
}

.table-btn-flex .table-btn,
.table-btn-flex .table-btn:hover {
  background-color: #08578a;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 22px;
  height: 20px;
}

.common-title-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.common-title-flex .common-title-lable {
  color: var(--lightblue-color);
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Medium";
}

.common-title-flex .common-value-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  word-break: break-word;
  font-family: "Poppins-Regular";
}

/* start auth footer social meadia */

.social-main .social-ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  margin: 20px 0px;
  justify-content: center;
}

.social-main .social-ul .social-li {
  padding: 0px;
  margin: 0px;
  width: max-content;
}

.social-box {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #d1d5db;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.social-main .social-ul .social-li:last-child .social-box {
  margin-right: 0px;
}

/* end auth footer social meadia */

.title-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.check-permission-flex {
  display: flex;
  gap: 20px;
  align-items: center;
}

.main-permission-box .permission-label {
  font-size: 13px;
  line-height: 19px;
  color: var(--primary-color);
  font-family: "Poppins-SemiBold";
  min-width: 220px;
}

.main-permission-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 50px;
}

.main-permission-box .MuiFormControlLabel-label {
  font-size: 13px;
  line-height: 19px;
  color: var(--secondary-color);
  font-family: "Poppins-medium";
}

.main-permission-box .role-check-box {
  padding: 0;
  margin-right: 5px;
}

.permission-right-main {
  display: flex;
  align-items: center;
  gap: 25px;
}

.common-btn-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.woops-icon {
  height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 30px;
}

.woops-body .woops-title {
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 32px;
  color: var(--primary-color);
  font-family: "Poppins-SemiBold";
  text-align: center;
}

.woops-body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.woops-body .woops-sub-title {
  color: var(--orange);
  font-size: 18px;
  line-height: 26px;
  font-family: "Poppins-Medium";
  text-align: center;
  margin-bottom: 15px;
}

.woops-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.woops-body .woops-message {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-color);
  font-family: "Poppins-Regular";
  text-align: center;
}

.admin-card-title-main .admin-card-title {
  font-size: 14px;
  line-height: 20px;
  color: var(--orange);
  font-family: "Poppins-Regular";
  margin-bottom: 15px;
}

.input-switch-flex {
  display: flex;
  align-items: center;
}

.input-switch-flex .input-switch-lable {
  min-width: 75px;
}

.time-form-group .MuiFormControl-root {
  min-width: 100% !important;
}

.payment-config-table-main .table {
  min-width: 1500px;
}

.accordian-main {
  margin-bottom: 15px;
}

.accordian-main:last-child {
  margin-bottom: 0;
}

.admin-input-bottom-border-field {
  width: 300px;
  background-color: var(--main-white);
}

.admin-input-bottom-border-field input {
  background-color: var(--main-white);
}

.admin-input-bottom-border-field .MuiFilledInput-root:before,
.admin-input-bottom-border-field .MuiFilledInput-root::after {
  border: none;
}

.day-title-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.day-title {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  word-break: break-word;
  font-family: "Poppins-Regular";
}

.pagination-main {
}

.pagination-count {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.pagination-design.flex-end {
  margin-top: 20px;
}

.pagination-select .MuiSelect-select {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 20px;
  color: var(--primary-color);
}

.countdown-box {
  margin: 15px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.countdown-box .countdown-text {
  font-size: 12px;
  line-height: 20px;
  color: var(--primary-color);
  text-align: center;
  font-family: "Poppins-Medium";
  margin-bottom: 0px;
}

.countdown-box .resend-text {
  color: var(--main-red);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  font-family: "Poppins-Medium";
  cursor: pointer;
}

.progress-job-box {
  display: flex;
  flex-direction: column;
  text-align: end;
  gap: 6px;
}

.form-control.w-100.remove-brder .MuiInputBase-root:before {
  display: none;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list {
  max-height: 200px;
}

#mui-component-select-zipCode {
  padding: 8px 12px 7px;
}

#mui-component-select-zipCode li,
ul
  li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters,
.css-lvwt62-MuiTypography-root {
  font-size: 12px !important;
}

.MuiDialogActions-root.MuiDialogActions-spacing.MuiPickersLayout-actionBar {
  justify-content: center;
}

.btn-green,
.btn-green:hover {
  background-color: #07bc0c !important;
}

.admin-icons.checkmark-icon {
  filter: invert(1);
}

.text-danger {
  color: #b90005 !important;
}

.form-group.pwd-icon-btn button {
  margin-right: 0px;
}

.MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 4px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1x6bjyf-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 54px !important;
}

.form-group.pwd-icon-btn.time-form-group input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.box-checkall {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.btn-main-primary .btn-primary.bg-suspend {
  color: #fff;
}

.profile-main.common-card.w-100 {
  max-width: 100%;
}

.officer-detail.officer-detail-flex .btn-main-primary .btn-primary {
  color: #fff;
}
.otp-verification-input-wrap {
  display: flex;
  justify-content: space-between;
}
.otp-verification-input {
  height: 50px;
  margin-right: 0px;
  width: 70px !important;
}
.form-group .hgt-address {
  height: 56px !important;
  line-height: 21px;
}

.address-list {
  background: #fff;
  padding: 8px;
  list-style-type: none;
  font-size: 12px;
  font-family: "Poppins-Regular";
  line-height: normal;
  color: #858585;
  border: 1px solid #d9d9d9;
}

.address-list li {
  margin-bottom: 8px;
}

.img-filter-invert {
  filter: brightness(0) invert(1);
}

.eye-input-box .MuiIconButton-root {
  margin-right: 2px;
}
